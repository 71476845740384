import React from 'react';

import PropTypes from 'prop-types';

import Grid from '@mui/material/Grid';

import TabPanelForm from '@/components/TabPanelForm';
import DividerTitle from '@/components/DividerTitle';

import useDatagridSx from '@/hooks/useDatagridSx';

import ParcelleCadastralePressentieSousContratTable from '@/features/shared/parcelle-cadastrale-pressentie-sous-contrat/ParcelleCadastralePressentieSousContratTable';
import ParcelleCadastraleSousContratTable from '@/features/shared/parcelle-sous-contrat/ParcelleCadastraleSousContratTable';
import CpcTable from './cpc/CpcTable';
import OrientationsGestionTable from './orientation-gestion/OrientationsGestionTable';
import UniteGestionTable from './unite-gestion/UniteGestionTable';
import SylvalibreContratLink from './sylvalibre-link/SylvalibreContratLink';

export default function SynchronisationSylvalibreTab({
  tabValue,
  unitesGestionList,
  parcellesCadastralesSousContratList,
  parcellesCadastralesPressentiesSousContratList,
  propriete,
  contratProprietaire,
  isSylvalibreSynced,
}) {

  const datagridSx = useDatagridSx();

  return (
    <TabPanelForm currentValue={tabValue} index={2}>
      <form>
        <SylvalibreContratLink propriete={propriete} contratProprietaire={contratProprietaire} />
        <Grid container sx={{ mt: 1 }} spacing={2}>
          <Grid item xs={12}>
            <DividerTitle text='Unité de gestion' />
          </Grid>
          <Grid item xs={12}>
            <UniteGestionTable
              unitesGestionList={unitesGestionList}
              datagridSx={datagridSx}
            />
          </Grid>
        </Grid>
        <Grid container sx={{ mt: 1 }} spacing={2}>
          <Grid item xs={12}>
            <DividerTitle text='Parcelles cadastrales incluses au contrat' />
          </Grid>
          <Grid item xs={12}>
            {isSylvalibreSynced || parcellesCadastralesSousContratList?.length > 0 ? (
              <ParcelleCadastraleSousContratTable
                parcellesCadastralesSousContratList={
                  parcellesCadastralesSousContratList
                }
                datagridSx={datagridSx}
                contratEdit={true}
              />
            ) : (
              <ParcelleCadastralePressentieSousContratTable
                items={parcellesCadastralesPressentiesSousContratList}
                title='Parcelles cadastrales pressenties sous contrat'
              />
            )}
          </Grid>
        </Grid>
        <Grid container sx={{ mt: 1 }} spacing={2}>
          <Grid item xs={12}>
            <DividerTitle text='Correspondance parcellaire cadastrale' />
          </Grid>
          <Grid item xs={12}>
            {unitesGestionList && (
              <CpcTable
                unitesGestionList={unitesGestionList}
                parcellesCadastralesSousContratList={
                  parcellesCadastralesSousContratList
                }
                datagridSx={datagridSx}
              />
            )}
          </Grid>
        </Grid>
        <Grid container sx={{ mt: 1 }} spacing={2}>
          <Grid item xs={12}>
            <DividerTitle text='Orientations de gestion' />
          </Grid>
          <Grid item xs={12}>
            <OrientationsGestionTable
              unitesGestionList={isSylvalibreSynced ? unitesGestionList : []}
              datagridSx={datagridSx}
            />
          </Grid>
        </Grid>
      </form>
    </TabPanelForm>
  );
}

SynchronisationSylvalibreTab.propTypes = {
  tabValue: PropTypes.number.isRequired,
  unitesGestionList: PropTypes.array,
  parcellesCadastralesSousContratList: PropTypes.array,
  parcellesCadastralesPressentiesSousContratList: PropTypes.array,
  propriete: PropTypes.object,
  contratProprietaire: PropTypes.object,
  isSylvalibreSynced: PropTypes.bool,
};
