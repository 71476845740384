import React, { useEffect, useMemo, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import PropTypes from 'prop-types';

import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

import BaseDataGrid from '@/components/BaseDataGrid';

import { clearUgList, setUgLink } from '@/slices/sylvalibreSlice';

export default function AddLinkFormDialog({ uniteGestion }) {
  const sylvalibreUgList = useSelector(
    (state) => state.sylvalibreReducer.ugList
  );

  const [open, setOpen] = useState(false);

  const dispatch = useDispatch();

  const isUgListNotEmpty = useMemo(
    () => sylvalibreUgList?.length > 0,
    [sylvalibreUgList]
  );

  useEffect(() => {
    setOpen(isUgListNotEmpty);
  }, [isUgListNotEmpty]);

  const columns = [
    {
      field: 'denomination_carte',
      headerName: 'Dénomination carte',
      width: 200,
      editable: false,
    },
    {
      field: 'surface_calculee',
      headerName: 'Surface calculée',
      width: 250,
      editable: false,
      valueFormatter: (value) => value && (value / 10000).toFixed(4),
    },
  ];

  const handleClose = () => {
    dispatch(clearUgList());
  };

  const [rowSelectionModel, setRowSelectionModel] = useState([]);

  const isValid = useMemo(() => {
    return rowSelectionModel.length === 1;
  }, [rowSelectionModel]);

  const handleValidate = () => {
    dispatch(
      setUgLink({
        ugUuid: rowSelectionModel[0],
        uniteGestionId: uniteGestion?.id,
        proprieteId: uniteGestion?.propriete?.id,
      })
    );
  };

  return (
    <Dialog open={open} fullWidth maxWidth='md'>
      <DialogTitle justifyContent='space-between'>
        <Grid container justifyContent='space-between'>
          <Grid item>Synchronisation Sylvalibre</Grid>
          <Grid item>
            <IconButton
              edge='start'
              color='inherit'
              onClick={handleClose}
              aria-label='close'
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent sx={{ p: 5 }}>
        <Grid
          container
          sx={{ mt: 1 }}
          spacing={2}
          alignItems='center'
          justifyContent='space-between'
        >
          <Grid item xs={12}>
            <Typography component='div' color='primary'>
              {
                "Choisissez dans la liste l'UG que vous souhaitez synchroniser :"
              }
            </Typography>
          </Grid>
          <Grid item xs={12}>
            {sylvalibreUgList && (
              <BaseDataGrid
                getRowId={({ uuid }) => uuid}
                columns={columns}
                rows={sylvalibreUgList}
                checkboxSelection={true}
                disableMultipleRowSelection={true}
                isRowSelectable={({ row }) => !row.lien_existant}
                onRowSelectionModelChange={(newRowSelectionModel) => {
                  setRowSelectionModel(newRowSelectionModel);
                }}
                rowSelectionModel={rowSelectionModel}
              />
            )}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleValidate}
          variant='contained'
          disabled={!isValid}
        >
          Valider
        </Button>
      </DialogActions>
    </Dialog>
  );
}

AddLinkFormDialog.propTypes = {
  uniteGestion: PropTypes.object,
};
