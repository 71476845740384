import React from 'react';

import deepmerge from 'deepmerge';
import PropTypes from 'prop-types';
import { useForm, useWatch } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';

import useChoices from '@/hooks/useChoices';
import useFetchFilteredItems from '@/hooks/useFetchFilteredItems';
import useGoBackHistory from '@/hooks/useGoBackHistory';
import useOnMount from '@/hooks/useOnMount';
import useSetFormValues from '@/hooks/useSetFormValues';
import useTabState from '@/hooks/useTabState';
import useConfirmationDialog from '@/hooks/useConfirmationDialog';
import useIsSylvalibreSynced from '@/hooks/useIsSylvalibreSynced';

import FormSubmitButtons from '@/features/shared/FormSubmitButtons';
import ContratsNeosylvaTab from '@/features/fiche-propriete/ContratsNeosylvaTab';
import EnjeuxEcoSociauxEnvTab from '@/features/fiche-propriete/EnjeuxEcoSociauxEnvTab';
import EquilibreForetGibierTab from '@/features/fiche-propriete/EquilibreForetGibierTab';
import GestionnaireCertificationSyndicatsTab from '@/features/fiche-propriete/GestionnaireCertificationSyndicatsTab';
import InformationsGeneralesTab from '@/features/fiche-propriete/InformationsGeneralesTab';
import StationForestiereTab from '@/features/fiche-propriete/StationForestiereTab';
import UniteGestionInterventionsTab from '@/features/fiche-propriete/UniteGestionInterventionsTab';

import { setIsLoading } from '@/slices/isLoadingSlice';
import { setMessage } from '@/slices/messageSlice';
import { setFilteredItems } from '@/slices/itemSlice';

import APIService from '@/APIService';

export default function FicheProprieteForm({ item, defaultTab }) {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const choices = useChoices('fiche-propriete');
  const fetchFilteredItems = useFetchFilteredItems();

  const [tabValue, handleTabChange] = useTabState(defaultTab);

  const goBackHistory = useGoBackHistory('/tableau-bord-cmf');

  const especesPlanChasseList = useSelector(
    (store) => store.itemReducer.filteredItems.especes_plan_chasse
  );
  const unitesGestionList = useSelector(
    (store) => store.itemReducer.filteredItems.unites_gestion
  );
  const certificationsForestiereList = useSelector(
    (store) => store.itemReducer.filteredItems.certifications
  );
  const dgdList = useSelector((store) => store.itemReducer.filteredItems.dgd);
  const stationsList = useSelector(
    (store) => store.itemReducer.filteredItems.stations
  );
  const accesOuvragesList = useSelector(
    (store) => store.itemReducer.filteredItems.acces_ouvrages
  );
  const elementsBiodiversiteList = useSelector(
    (store) => store.itemReducer.filteredItems.elements_biodiversite
  );
  const proprietairesList = useSelector(
    (store) => store.itemReducer.filteredItems.proprietaires
  );
  const parcellesPressentiesList = useSelector(
    (store) =>
      store.itemReducer.filteredItems
        .parcelles_cadastrales_pressenties_sous_contrat
  );

  const parcellesCadastralesSousContratList = useSelector(
    (store) =>
      store.itemReducer.filteredItems.parcelles_cadastrales_sous_contrat
  );

  const contratsProprietaireList = useSelector(
    (store) => store.itemReducer.filteredItems.contrats_proprietaires
  );
  const isFetching = useSelector(
    (store) => store.itemReducer.pendingActions !== 0
  );

  const isSylvalibreSynced = useIsSylvalibreSynced(unitesGestionList);

  const form1 = useForm({
    defaultValues: {
      propriete: {
        nom_commun: null,
        adresse: null,
        commune: null,
        code_postal: null,
        departement: null,
        surface_totale_propriete: null,
        cmf_neosylva: null,
      },
    },
    reValidateMode: 'onBlur',
    mode: 'onChange',
  });
  const {
    control: control1,
    setValue: setValue1,
    getValues: getValues1,
    formState: { isValid: isValid1 },
  } = form1;

  const nomProprieteWatch = useWatch({
    control: control1,
    name: 'propriete.nom_commun',
  });

  const form2 = useForm({
    defaultValues: {
      propriete: {
        gestionnaire_preexistant: false,
        gestionnaire: null,
        objectifs_gestion_list: [],
        historique_gestion: null,
        adhesion_syndicat: false,
        adhesion_syndicat_detail: null,
        existence_dgd: false,
        certification_forestiere: false,
      },
    },
    reValidateMode: 'onBlur',
    mode: 'onChange',
  });
  const { setValue: setValue2, getValues: getValues2 } = form2;

  const form3 = useForm({
    defaultValues: {
      propriete: {
        acces_massif: null,
        acces_grumier: null,
        nb_place_depot: null,
        besoin_acces_supplementaire: null,
        description_ressource_forestiere: null,
        description_filiere_bois: null,
        charges: null,
        ressources: null,
        equipement_defense_incendie: null,
        enjeux_economiques_gestion_forestiere: null,
        droits_usage_servitudes_divers: null,
        frequentation_public: false,
        importance_frequentation: null,
        mesure_accueil_public: false,
        mesure_reduction_frequentation: false,
        balisage_particulier: false,
        convention_ouverture_publique: false,
        impact_frequentation_gestion: null,
        zonages_identifies_list: [],
        zonages_identifies_commentaire: null,
      },
    },
    reValidateMode: 'onBlur',
    mode: 'onChange',
  });
  const { getValues: getValues3, setValue: setValue3 } = form3;

  const form4 = useForm({
    defaultValues: {
      propriete: {
        acca: false,
        societe_chasse: false,
        reservataire: false,
        plan_chasse: false,
        degats_constates: null,
        natures_degats_list: null,
        commentaire_chasse_pression_gibier: null,
        identite_detenteur_droit_chasse: null,
      },
    },
    reValidateMode: 'onBlur',
    mode: 'onChange',
  });
  const { getValues: getValues4, setValue: setValue4 } = form4;

  const form5 = useForm({
    defaultValues: {
      propriete: {
        catalogue_station: false,
        catalogue_station_detail: null,
      },
    },
    reValidateMode: 'onBlur',
    mode: 'onChange',
  });
  const { getValues: getValues5, setValue: setValue5 } = form5;

  const proprieteFields = [
    ['nom_commun', true, setValue1],
    ['adresse', false, setValue1],
    ['commune', false, setValue1],
    ['code_postal', false, setValue1],
    ['departement', false, setValue1],
    ['surface_totale_propriete', false, setValue1],
    ['cmf_neosylva', true, setValue1],
    ['gestionnaire_preexistant', false, setValue2],
    ['gestionnaire', false, setValue2],
    ['objectifs_gestion_list', false, setValue2],
    ['historique_gestion', false, setValue2],
    ['adhesion_syndicat', false, setValue2],
    ['adhesion_syndicat_detail', false, setValue2],
    ['existence_dgd', false, setValue2],
    ['certification_forestiere', false, setValue2],
    ['acces_massif', false, setValue3],
    ['acces_grumier', false, setValue3],
    ['nb_place_depot', false, setValue3],
    ['besoin_acces_supplementaire', false, setValue3],
    ['description_ressource_forestiere', false, setValue3],
    ['description_filiere_bois', false, setValue3],
    ['charges', false, setValue3],
    ['ressources', false, setValue3],
    ['equipement_defense_incendie', false, setValue3],
    ['enjeux_economiques_gestion_forestiere', false, setValue3],
    ['droits_usage_servitudes_divers', false, setValue3],
    ['frequentation_public', false, setValue3],
    ['importance_frequentation', false, setValue3],
    ['mesure_accueil_public', false, setValue3],
    ['mesure_reduction_frequentation', false, setValue3],
    ['balisage_particulier', false, setValue3],
    ['convention_ouverture_publique', false, setValue3],
    ['impact_frequentation_gestion', false, setValue3],
    ['zonages_identifies_list', false, setValue3],
    ['zonages_identifies_commentaire', false, setValue3],
    ['acca', false, setValue4],
    ['societe_chasse', false, setValue4],
    ['reservataire', false, setValue4],
    ['plan_chasse', false, setValue4],
    ['degats_constates', false, setValue4],
    ['natures_degats_list', false, setValue4],
    ['commentaire_chasse_pression_gibier', false, setValue4],
    ['identite_detenteur_droit_chasse', false, setValue4],
    ['catalogue_station', false, setValue5],
    ['catalogue_station_detail', false, setValue5],
  ];

  const setFormValues = useSetFormValues(proprieteFields);

  const fetchProprieteRelatedItems = (proprieteId) => {
    fetchFilteredItems({
      itemTypes: ['contrats_proprietaires', 'proprietaires'],
      filter: { item_type: 'propriete', item_id: proprieteId },
    });

    fetchFilteredItems({
      itemTypes: [
        'acces_ouvrages',
        'elements_biodiversite',
        'especes_plan_chasse',
        'dgd',
        'certifications',
        'stations',
        'parcelles_cadastrales_pressenties_sous_contrat',
      ],
      filter: { propriete_id: proprieteId },
    });

    fetchFilteredItems({
      itemTypes: ['unites_gestion', 'parcelles_cadastrales_sous_contrat'],
      filter: { item_id: proprieteId, item_type: 'propriete' },
    });
  };

  useOnMount(() => {
    if (!item?.id) {
      [
        'proprietaires',
        'parcelles_cadastrales_pressenties_sous_contrat',
        'contrats_proprietaires',
        'acces_ouvrages',
        'elements_biodiversite',
        'especes_plan_chasse',
        'unites_gestion',
        'dgd',
        'certifications',
        'stations',
      ].forEach((itemType) => {
        dispatch(
          setFilteredItems({
            itemsList: [],
            itemType,
          })
        );
      });

      return;
    }

    setFormValues(proprieteFields, item, 'propriete');

    fetchProprieteRelatedItems(item.id);
  });

  const { open } = useConfirmationDialog();

  const redirectToTableauDeBordCMF = async () => {
    const result = await open({
      content: 'Voulez-vous sauvegarder avant de quitter la fiche propriété ?',
      confirmButton: 'Oui',
      alternativeButton: 'Non',
    });

    if (result === 'confirm') {
      dispatch(setIsLoading(true));
      if (!isValid1 || isFetching) {
        dispatch(
          setMessage(
            'Sauvegarde impossible, veuillez remplir les champs obligatoires de la fiche propriété.'
          )
        );
        dispatch(setIsLoading(false));
        return;
      }
      onSubmit(false)
        .then(() => {
          navigate('/tableau-bord-cmf');
        })
        .finally(() => dispatch(setIsLoading(false)));
    } else if (result === 'alternative') {
      navigate('/tableau-bord-cmf');
    }
  };

  const onSubmit = async (refresh = true) => {
    const formValuesList = [
      getValues1(),
      getValues2(),
      getValues3(),
      getValues4(),
      getValues5(),
    ];

    const formValues = deepmerge.all(formValuesList);
    formValues.contrats_proprietaires_list = contratsProprietaireList;
    formValues.especes_plan_chasse_list = especesPlanChasseList;
    formValues.acces_ouvrages_list = accesOuvragesList;
    formValues.elements_biodiversite_list = elementsBiodiversiteList;
    formValues.dgd_list = dgdList;
    formValues.certifications_forestieres_list =
      certificationsForestiereList.map((certification) => ({
        ...certification,
        propriete: formValues.propriete,
      }));
    formValues.proprietaires_list = proprietairesList;
    formValues.parcelles_cadastrales_pressenties_sous_contrat_list =
      parcellesPressentiesList;
    formValues.parcelles_cadastrales_sous_contrat_list = parcellesCadastralesSousContratList;
    formValues.propriete.id = item?.id;

    formValues.stations_list = stationsList;

    const requestParams = {
      url: item?.id ? `fiche-propriete/${item.id}` : 'fiche-propriete',
      data: formValues,
      onError: (res) => {
        const message = res.data.message || res.data.detail;
        if (message) {
          dispatch(setMessage(message));
        }
      },
    };

    const requestMethod = item?.id ? 'patch' : 'post';
    return await APIService[requestMethod](requestParams)
      .then(({ data: { propriete_id, message } }) => {
        if (requestMethod === 'post') {
          navigate(`/fiche-propriete/${propriete_id}?tab=${tabValue}`, {
            replace: true,
          });
        } else if (refresh) {
          fetchProprieteRelatedItems(item?.id);
        }
      
        dispatch(setMessage(message));
        return propriete_id;
      })
      .finally(() => dispatch(setIsLoading(false)));
  };

  return (
    <Box elevation={3} component={Paper} sx={{ width: '100%', p: 3 }}>
      <Breadcrumbs aria-label='breadcrumb'>
        <Link
          underline='hover'
          color='inherit'
          onClick={redirectToTableauDeBordCMF}
        >
          Tableau de bord CMF
        </Link>
        {item.id && (
          <Typography sx={{ color: 'text.primary' }}>
            Propriété {nomProprieteWatch}
          </Typography>
        )}
      </Breadcrumbs>
      <Typography mb={2} variant='h5' component='div'>
        {item.id ? 'Édition' : 'Création'} fiche propriété
      </Typography>
      <Tabs
        value={tabValue}
        onChange={handleTabChange}
        variant='scrollable'
        scrollButtons
        allowScrollButtonsMobile
      >
        <Tab label='Informations générales' />
        <Tab label='Gestionnaire, certification, syndicats' />
        <Tab label='Enjeux économiques, sociaux et environnementaux' />
        <Tab label='Équilibre forêt-gibier' />
        <Tab label='Stations forestières' />
        <Tab label='Unités de gestion & interventions sylvicoles' />
        <Tab label='Contrats Néosylva' />
      </Tabs>
      <InformationsGeneralesTab
        tabValue={tabValue}
        form={form1}
        choices={choices}
        proprietairesList={proprietairesList}
        parcellesPressentiesList={parcellesPressentiesList}
        parcellesCadastralesSousContratList={parcellesCadastralesSousContratList}
        propriete={item}
        isSylvalibreSynced={isSylvalibreSynced}
      />
      <GestionnaireCertificationSyndicatsTab
        tabValue={tabValue}
        form={form2}
        choices={choices}
        certificationsForestiereList={certificationsForestiereList}
        dgdLists={dgdList}
      />
      <EnjeuxEcoSociauxEnvTab
        tabValue={tabValue}
        form={form3}
        choices={choices}
        accesOuvragesList={accesOuvragesList}
        elementsBiodiversiteList={elementsBiodiversiteList}
      />
      <EquilibreForetGibierTab
        tabValue={tabValue}
        form={form4}
        choices={choices}
        especesPlanChasseList={especesPlanChasseList}
      />
      <StationForestiereTab
        tabValue={tabValue}
        form={form5}
        stationsList={stationsList}
      />
      <UniteGestionInterventionsTab
        tabValue={tabValue}
        unitesGestionList={unitesGestionList}
      />
      <ContratsNeosylvaTab
        tabValue={tabValue}
        onSubmit={onSubmit}
        disabledSubmit={!isValid1 || isFetching}
        contratsProprietaireList={contratsProprietaireList}
      />
      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <FormSubmitButtons
          onSubmit={onSubmit}
          submitLabel={'Sauvegarder'}
          onCancel={goBackHistory}
          disabledSubmit={!isValid1 || isFetching}
        />
      </Box>
    </Box>
  );
}

FicheProprieteForm.propTypes = {
  item: PropTypes.object.isRequired,
  defaultTab: PropTypes.string,
};
