import React, { useMemo } from 'react';

import { useDispatch } from 'react-redux';

import PropTypes from 'prop-types';

import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import { useGridApiRef } from '@mui/x-data-grid';
import Typography from '@mui/material/Typography';

import CellExpand from '@/components/CellExpand';
import BaseDataGrid from '@/components/BaseDataGrid';
import { setFilteredItems } from '@/slices/itemSlice';

export default function ParcelleCadastraleSousContratTable({
  parcellesCadastralesSousContratList,
  datagridSx,
  contratEdit = false,
}) {
  const apiRef = useGridApiRef();

  const columns = [
    {
      field: 'prefixe',
      headerName: 'Préfixe',
      width: 100,
      editable: false,
    },
    {
      field: 'section',
      headerName: 'Section',
      width: 100,
      editable: false,
    },
    {
      field: 'numero',
      headerName: 'N°',
      width: 100,
      editable: false,
    },
    {
      field: 'nom_commune',
      headerName: 'Commune',
      width: 240,
      editable: false,
      renderCell: ({ row }) => <CellExpand value={row.nom_commune} />,
    },
    {
      field: 'proprietaire',
      headerName: 'Nom(s) du ou des propriétaire(s)',
      width: 250,
      editable: true,
      renderCell: ({ row }) => {
        <CellExpand
          value={row.proprietaire}
          iconButton
          editIcon={
            <IconButton
              color='primary'
              onClick={() =>
                apiRef.current.startCellEditMode({
                  id: row.id,
                  field: 'proprietaire',
                })
              }
            >
              <EditIcon fontSize='small' />
            </IconButton>
          }
        />;
      },
    },
    {
      field: 'contenance',
      headerName: 'Surface totale de la parcelle cadastrale (ha)',
      width: 240,
      type: 'number',
      editable: false,
      valueFormatter: (value) => {
        if (typeof value === 'string') {
          value = Number(value);
        }
        return value ? value.toFixed(4) : null;
      },
    },
    {
      field: 'surface_sig',
      headerName: 'Surface calculée de la parcelle cadastrale (ha)',
      width: 240,
      type: 'number',
      editable: false,
      valueFormatter: (value) => {
        if (typeof value === 'string') {
          value = Number(value);
        }
        return value ? value.toFixed(4) : null;
      },
    },
    {
      field: 'entierement_inclus_contrat',
      headerName: 'Entièrement intégrée au contrat ?',
      width: 200,
      type: 'boolean',
      editable: contratEdit,
    },
    {
      field: 'surface_cadastrale_retenue_contrat',
      headerName: 'Surface de la parcelle cadastrale intégrée au contrat (ha)',
      width: 240,
      type: 'number',
      editable: false,
      valueFormatter: (value) => {
        if (typeof value === 'string') {
          value = Number(value);
        }
        return value ? value.toFixed(4) : null;
      },
    },
  ];

  const dispatch = useDispatch();

  const totalArea = useMemo(() => {
    if (!parcellesCadastralesSousContratList) {
      return 0;
    }

    const total = parcellesCadastralesSousContratList
      .filter((p) => p.surface_cadastrale_retenue_contrat)
      .reduce((acc, val) => acc + val.surface_cadastrale_retenue_contrat, 0);

    return total.toFixed(4);
  }, [parcellesCadastralesSousContratList]);

  return (
    <>
      <BaseDataGrid
        rows={parcellesCadastralesSousContratList}
        columns={columns}
        sx={datagridSx}
        apiRef={apiRef}
        alternateRowColor
        headerClassName='datagrid-header'
        processRowUpdate={(updatedRow) => {
          dispatch(
            setFilteredItems({
              itemsList: parcellesCadastralesSousContratList.map((parcelle) => {
                if (parcelle.id === updatedRow.id) {
                  return updatedRow;
                }
                return parcelle;
              }),
              itemType: 'parcelles_cadastrales_sous_contrat',
            })
          );

          return updatedRow;
        }}
      />
      <Typography
        mt={1}
        variant='subtitle2'
        component='div'
        sx={{ color: 'primary.light' }}
      >
        Surface totale cadastrale sous contrat : {totalArea} ha
      </Typography>
    </>
  );
}

ParcelleCadastraleSousContratTable.propTypes = {
  parcellesCadastralesSousContratList: PropTypes.array,
  datagridSx: PropTypes.object.isRequired,
  contratEdit: PropTypes.bool,
};
