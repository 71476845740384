import React from 'react';

import PropTypes from 'prop-types';

import ProprietaireFormDialog from '@/features/shared/proprietaire/ProprietaireFormDialog';
import CRUDDataGrid from '@/components/CRUDDataGrid';

export default function ProprietaireTable({ items, disabledEdit=false }) {
  const columns = [
    {
      field: 'nom',
      headerName: 'Nom',
      width: 140,
      editable: false,
      valueGetter: (_, { contact }) => contact.nom,
    },
    {
      field: 'prenom',
      headerName: 'Prénom',
      width: 240,
      editable: false,
      valueGetter: (_, { contact }) => contact.prenom,
    },
    {
      field: 'adresse',
      headerName: 'Adresse',
      width: 200,
      editable: false,
      valueGetter: (_, { contact }) => contact.adresse,
    },
    {
      field: 'code_postal',
      headerName: 'Code postal',
      width: 260,
      valueGetter: (_, { contact }) => contact.code_postal,
    },
    {
      field: 'commune',
      headerName: 'Commune',
      width: 200,
      editable: false,
      valueGetter: (_, { contact }) => contact.commune,
    },
    {
      field: 'email',
      headerName: 'E-mail',
      width: 200,
      editable: false,
      valueGetter: (_, { contact }) => contact.email,
    },
    {
      field: 'telephone_portable',
      headerName: 'Téléphone',
      width: 200,
      editable: false,
      valueGetter: (_, { contact }) =>
        contact.telephone_portable || contact.telephone_fixe,
    },
    {
      field: 'personnalite_juridique',
      headerName: 'Personnalité juridique',
      width: 200,
      editable: false,
      valueGetter: (value) => value?.nom,
    },
  ];

  return (
    <CRUDDataGrid
      itemsList={items}
      columns={columns}
      itemType={'proprietaires'}
      addItemLabel={'Ajouter un propriétaire'}
      modal={{
        component: ProprietaireFormDialog,
        props: { proprietairesList: items },
      }}
      disabledEdit={disabledEdit}
    />
  );
}

ProprietaireTable.propTypes = {
  items: PropTypes.array,
  disabledEdit: PropTypes.bool,
};
