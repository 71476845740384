import React, { useEffect, useMemo } from 'react';

import PropTypes from 'prop-types';
import { useWatch } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import Grid from '@mui/material/Grid';

import useFetchFilteredItems from '@/hooks/useFetchFilteredItems';
import useIsGestionnaire from '@/hooks/useIsGestionnaire';
import useItems from '@/hooks/useItems';

import DatePickerField from '@/components/controlled-fields/DatePickerField';
import SelectField from '@/components/controlled-fields/SelectField';
import SwitchField from '@/components/controlled-fields/SwitchField';
import AreaTextField from '@/components/controlled-fields/text-fields/AreaTextField';
import BaseTextField from '@/components/controlled-fields/text-fields/BaseTextField';
import CodePostalTextField from '@/components/controlled-fields/text-fields/CodePostalTextField';
import DividerTitle from '@/components/DividerTitle';
import TabPanelForm from '@/components/TabPanelForm';

import AdresseField from '@/features/shared/AdresseField';
import DepartementField from '@/features/shared/DepartementField';
import ContratProprietaireAutocompleteField from '@/features/shared/ContratProprietaireAutocompleteField';
import GestionnaireAutocompleteField from '@/features/shared/GestionnaireAutocompleteField';
import ReferentTechniqueFormDialog from '@/features/shared/referent-technique/ReferentTechniqueFormDialog';
import ParcelleCadastralePressentieSousContratTable from '@/features/shared/parcelle-cadastrale-pressentie-sous-contrat/ParcelleCadastralePressentieSousContratTable';
import ProprietaireTable from '@/features/shared/proprietaire/ProprietaireTable';

import { setFilteredItems } from '@/slices/itemSlice';

import APIService from '@/APIService';

export default function RenseignementsGenerauxTab({
  form,
  tabValue,
  choices,
  proprieteState,
  gestionnaireState,
  contratProprietaireState,
  proprietairesList,
  parcellesList,
  contratId,
}) {
  const fetchFilteredItems = useFetchFilteredItems();
  const { control, setValue } = form;
  const { contratProprietaire, setContratProprietaire } =
    contratProprietaireState;

  const { gestionnaire, setGestionnaire } = gestionnaireState;
  const { propriete, setPropriete } = proprieteState;

  const dispatch = useDispatch();

  const user = useSelector((store) => store.persistAuthReducer.user);

  const isGestionnaire = useIsGestionnaire();
  const gestionnaires = isGestionnaire ? [] : useItems('gestionnaires_pro');
  const usersList = isGestionnaire ? [] : useItems('users');

  const cmfUsers = useMemo(() => {
    let users;
    if (isGestionnaire) {
      users = [contratProprietaire?.propriete?.cmf_neosylva];
    } else {
      users = usersList?.filter((user) => user.role.name === 'neosylva_cmf');
    }
    return users;
  }, [contratProprietaire, usersList]);

  const [gestionnaireWatch, referentTechniqueWatch] = useWatch({
    control,
    name: ['propriete.gestionnaire', 'contrat_proprietaire.referent_technique'],
  });

  const contratsProprietaires = useSelector(
    (store) => store.itemReducer.filteredItems.contrats_proprietaires
  );

  const fetchPropriete = async (proprieteId) => {
    const proprieteRes = await APIService.get({
      url: `proprietes/${proprieteId}`,
    });
    setPropriete(proprieteRes.data);
  };

  useEffect(() => {
    gestionnaireWatch && setGestionnaire(gestionnaireWatch);
  }, [gestionnaireWatch]);

  useEffect(() => {
    if (!contratProprietaire) {
      return;
    }

    fetchPropriete(contratProprietaire.propriete.id);
    setGestionnaire(propriete?.gestionnaire);

    dispatch(
      setFilteredItems({
        itemsList: contratProprietaire.sharepoints_list.concat(
          contratProprietaire.files_list
        ),
        itemType: 'documents',
      })
    );

    fetchFilteredItems({
      filter: {
        item_type: 'contrat_proprietaire',
        item_id: contratProprietaire.id,
      },
      itemTypes: ['unites_gestion'],
    });

    setValue('contrat_proprietaire', contratProprietaire, {
      shouldValidate: true,
    });
    return () => {
      setPropriete(null);
    };
  }, [contratProprietaire]);

  useEffect(() => {
    if (contratsProprietaires || contratId) {
      return;
    }
    fetchFilteredItems({
      filter: { item_type: 'diagnostic', item_id: user?.gestionnaire_id },
      itemTypes: ['contrats_proprietaires'],
    });
  }, [contratsProprietaires]);

  const codePostalProprieteWatch = useWatch({
    control,
    name: 'propriete.code_postal',
  });

  return (
    <TabPanelForm currentValue={tabValue} index={0}>
      <form>
        <Grid container spacing={2} alignItems='center'>
          <Grid item xs={12} sm={6} md={3}>
            {!contratId && (
              <ContratProprietaireAutocompleteField
                currentContratProprietaire={contratProprietaire}
                contratsProprietaires={contratsProprietaires}
                setContratProprietaire={setContratProprietaire}
              />
            )}
          </Grid>
        </Grid>
        {contratProprietaire && propriete && (
          <>
            <Grid container sx={{ mt: 1 }} spacing={2} alignItems='center'>
              <Grid item xs={12}>
                <DividerTitle text={'Interlocuteurs'} />
              </Grid>
            </Grid>
            <Grid container sx={{ mt: 1 }} spacing={2} alignItems='center'>
              <Grid item xs={12} sm={6} md={3}>
                <SelectField
                  control={control}
                  name='propriete.cmf_neosylva'
                  required='CMF Néosylva requis.'
                  label='CMF Néosylva'
                  values={cmfUsers}
                  itemMapper={(user) => ({
                    key: user.username,
                    label: `${user.first_name} ${user.last_name}`,
                  })}
                  baseId='neosylva-cmf'
                  disabled={isGestionnaire}
                  renderValue={(value) =>
                    `${value.first_name} ${value.last_name}`
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <GestionnaireAutocompleteField
                  control={control}
                  disabled={isGestionnaire}
                  name={'propriete.gestionnaire'}
                  verboseName={'Gestionnaire'}
                  gestionnaires={gestionnaires}
                  defaultGestionnaire={gestionnaire}
                  setValue={setValue}
                  required={true}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <DatePickerField
                  control={control}
                  name='contrat_proprietaire.date_visite_gestionnaire'
                  label='Date de visite du Gestionnaire'
                />
              </Grid>
            </Grid>
            <Grid container sx={{ mt: 1 }} spacing={2} alignItems='center'>
              <Grid item xs={12}>
                <DividerTitle text={'Informations propriété'} />
              </Grid>
            </Grid>
            <Grid container sx={{ mt: 1 }} spacing={2} alignItems='center'>
              <Grid item xs={12} sm={6} md={3}>
                <BaseTextField
                  control={control}
                  name='propriete.nom_commun'
                  maxLength={256}
                  required='Nom de la propriété requise.'
                  label='Nom de la propriété'
                />
              </Grid>
              <Grid item xs={12} sm={7} md={5}>
                <AdresseField
                  control={control}
                  fieldName={'propriete.adresse'}
                  relatedFiedlNames={{
                    commune: 'propriete.commune',
                    codePostal: 'propriete.code_postal',
                  }}
                  label={'Adresse propriété'}
                  setValue={setValue}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <BaseTextField
                  control={control}
                  name='propriete.commune'
                  maxLength={256}
                  label='Commune propriété'
                />
              </Grid>
              <Grid item xs={12} sm={6} md={2}>
                <CodePostalTextField
                  control={control}
                  name='propriete.code_postal'
                  label='Code postal propriété'
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <DepartementField
                  control={control}
                  label={'Département propriété'}
                  departements={choices?.departements}
                  departementFieldName={'propriete.departement'}
                  codePostalFieldName={'propriete.code_postal'}
                  setValue={setValue}
                  required={
                    !codePostalProprieteWatch && 'Département propriété requis.'
                  }
                  disabled={!!codePostalProprieteWatch}
                />
              </Grid>
            </Grid>
            <Grid container sx={{ mt: 1 }} spacing={2} alignItems='center'>
              <Grid item xs={12} sm={6} md={3}>
                <AreaTextField
                  control={control}
                  name='propriete.surface_totale_propriete'
                  label='Surface totale propriété (ha)'
                />
              </Grid>
              <Grid item xs={10}>
                <ParcelleCadastralePressentieSousContratTable
                  items={parcellesList}
                  title={'Parcelles cadastrales pressenties sous contrat'}
                />
              </Grid>
            </Grid>
            <Grid container sx={{ mt: 1 }} spacing={2} alignItems='center'>
              <Grid item xs={12} sm={6} md={2}>
                <SwitchField
                  control={control}
                  name='propriete.existence_dgd'
                  label='Existence DGD'
                />
              </Grid>
            </Grid>
            <Grid container sx={{ mt: 1 }} spacing={2} alignItems='center'>
              <Grid item xs={12}>
                <DividerTitle text={'Informations propriétaire'} />
              </Grid>
            </Grid>
            <Grid container item spacing={2} mt={1}>
              <Grid item xs={12}>
                <ProprietaireTable items={proprietairesList} disabledEdit={isGestionnaire} />
              </Grid>
            </Grid>
            <Grid container item spacing={2}>
              <Grid item xs={12}>
                <DividerTitle text='Référent technique' />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <ReferentTechniqueFormDialog
                  item={referentTechniqueWatch}
                  onSubmit={(value) =>
                    setValue('contrat_proprietaire.referent_technique', value)
                  }
                  proprietairesContactIds={proprietairesList?.map(
                    (item) => item.contact.id
                  )}
                />
              </Grid>
            </Grid>
          </>
        )}
      </form>
    </TabPanelForm>
  );
}

RenseignementsGenerauxTab.propTypes = {
  form: PropTypes.object.isRequired,
  tabValue: PropTypes.number.isRequired,
  choices: PropTypes.object,
  proprieteState: PropTypes.object.isRequired,
  gestionnaireState: PropTypes.object.isRequired,
  contratProprietaireState: PropTypes.object.isRequired,
  proprietairesList: PropTypes.array,
  parcellesList: PropTypes.array,
  contratId: PropTypes.string,
};
